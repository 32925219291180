import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Grid,
  IconButton,
  Input,
  InputGroup,
  Modal,
  Nav,
  Panel,
  Row,
  SelectPicker,
  Stack,
} from 'rsuite';
import SearchIcon from '@rsuite/icons/Search';
import { FaFileExport, FaFilter } from 'react-icons/fa';
import { Icon } from '@rsuite/icons';
import { useStoreActions, useStoreState } from '../store/hooks';
import ProductTable from '../components/Products/ProductTable';
import ProductFilter from '../components/Products/ProductFilter';
import { Eshop } from '../store/catalogs/types';
import { exportProductList } from '../api/products';
import { downloadData } from '../utils/downloadData';
import ProductBulkActionDropdown from '../components/Products/bulkaction/ProductBulkActionDropdown';
import { ApiGroup } from '../api/model/ApiGroup';

export default function Products() {
  const { setFilter, setApiGroup } = useStoreActions(actions => actions.products);
  const { fetchEshops } = useStoreActions(actions => actions.catalogs);
  const currentFilter = useStoreState(state => state.products.currentFilter);
  const apiGroups = useStoreState(state => state.catalogs.apiGroups);
  const totalProducts = useStoreState(state => state.products.total);

  const selectedApiGroup = useStoreState(state => state.products.apiGroup);

  const [globalFilter, setGlobalFilter] = useState(currentFilter.filter.globalFilter);
  const [filterVisible, setFilterVisible] = useState(false);
  const [exportConfirmOpen, setExportConfirmOpen] = useState(false);
  const [exporting, setExporting] = useState(false);

  useEffect(() => {
    fetchEshops().then((eshops: Eshop[]) => {
      if (eshops.length > 0) {
        setApiGroup(eshops[0].apiGroup);
      }
    });
  }, [fetchEshops]);

  const globalSearch = () => {
    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        ...currentFilter.filter,
        globalFilter,
      },
    });
  };

  const changeApiGroup = useCallback((value: ApiGroup) => {
    setApiGroup(value);

    setFilter({
      ...currentFilter,
      page: 1,
      filter: {
        ...currentFilter.filter,
        eshopIds: [],
      },
    });
  }, [currentFilter]);

  const getFiltersCount = (): number | boolean => {
    const { filter } = currentFilter;
    let count = 0;

    if (filter.eshopIds.length > 0) count += 1;
    if (filter.states.length > 0) count += 1;
    if (filter.categories.length > 0) count += 1;
    if (filter.brands.length > 0)count += 1;
    if (filter.productCode) count += 1;
    if (filter.ean) count += 1;
    if (filter.title) count += 1;
    if (filter.priceRange && (filter.priceRange?.from || filter.priceRange?.to)) count += 1;
    if (filter.stockRange && (filter.stockRange?.from || filter.stockRange?.to)) count += 1;
    if (filter.weightRange && (filter.weightRange?.from || filter.weightRange?.to)) count += 1;
    if (filter.onlyErrors) count += 1;
    if (filter.handlingTimeRange
        && (filter.handlingTimeRange?.from || filter.handlingTimeRange?.to)) count += 1;

    return count === 0 ? false : count;
  };

  const exportProducts = async () => {
    setExporting(true);
    const request = {
      ...currentFilter,
      filter: {
        ...currentFilter.filter,
      },
    };

    try {
      const response = await exportProductList(selectedApiGroup!, request);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setExporting(false);
      setExportConfirmOpen(false);
    }
  };

  return (
    <Panel className="h100p">
      <Grid fluid className="h100p">
        <Row>
          <Col xs={24}>
            <Nav className="nav-large" appearance="subtle">
              <Nav.Item eventKey="all">Overview</Nav.Item>
            </Nav>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Stack direction="column" alignItems="flex-start" className="mt-20">
              <Stack spacing={40}>
                <SelectPicker
                  style={{ width: 200 }}
                  searchable={false}
                  cleanable={false}
                  data={apiGroups.map(s => ({ value: s, label: s }))}
                  block
                  value={selectedApiGroup}
                  onChange={changeApiGroup}
                />
                <InputGroup inside>
                  <Input
                    style={{ width: 400 }}
                    placeholder="search by SKU, EAN, MP ID, title, category, brand"
                    defaultValue={globalFilter}
                    onChange={setGlobalFilter}
                    onKeyUp={(ev) => {
                      if (ev.key === 'Enter') {
                        globalSearch();
                      }
                    }}
                  />
                  <InputGroup.Button>
                    <SearchIcon onClick={globalSearch} />
                  </InputGroup.Button>
                </InputGroup>

                <Badge content={getFiltersCount()} color="green">
                  <IconButton
                    style={{ width: 140 }}
                    appearance="primary"
                    onClick={() => setFilterVisible(!filterVisible)}
                    placement="right"
                    icon={<Icon as={FaFilter} />}
                  >
                    Advance filter
                  </IconButton>
                </Badge>

                <IconButton
                  style={{ width: 140 }}
                  onClick={() => setExportConfirmOpen(true)}
                  appearance="primary"
                  icon={<Icon as={FaFileExport} />}
                  placement="right"
                >
                  Export
                </IconButton>
                <ProductBulkActionDropdown />
              </Stack>

              <ProductFilter visible={filterVisible} />

            </Stack>
          </Col>
        </Row>
        <Row className={filterVisible ? 'product-table-container-filter' : 'product-table-container'}>
          <Col xs={24} className="mt-20 h100p">
            <ProductTable />
          </Col>
        </Row>
      </Grid>
      <Modal backdrop="static" role="alertdialog" open={exportConfirmOpen} onClose={() => setExportConfirmOpen(false)} size="xs">
        <Modal.Header closeButton={false}>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to export
          {' '}
          <b>{totalProducts}</b>
          {' '}
          products/variants?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => exportProducts()} appearance="primary" loading={exporting}>
            Ok
          </Button>
          <Button onClick={() => setExportConfirmOpen(false)} appearance="subtle" disabled={exporting}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </Panel>
  );
}
