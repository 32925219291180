import React, { useEffect, useReducer } from 'react';
import {
  Button, Checkbox, CheckPicker, Col, FlexboxGrid, Form, Input, InputNumber, Stack,
} from 'rsuite';
import { ItemDataType } from 'rsuite/esm/@types/common';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  HoldStatus, ProductListFilter, ProductsFilterData, ProductStates,
} from '../../api/apiTypes';
import { getProductsFilterData } from '../../api/products';
import { ApiGroup } from '../../api/model/ApiGroup';

function getHoldStatusesData(): ItemDataType[] {
  const labels: { [key in HoldStatus]: string } = {
    [HoldStatus.ON_HOLD]: 'On hold',
    [HoldStatus.NOT_ON_HOLD]: 'Not on hold',
  };

  return Object.values(HoldStatus)
    .sort((a, b) => a.localeCompare(b))
    .map(status => ({ value: status, label: labels[status] }));
}

interface Filter {
  selectedStates: ProductStates[];
  selectedEshops: number[];
  selectedCategories: string[];
  selectedBrands: string[];
  priceFrom: string;
  priceTo: string;
  stockFrom: string;
  stockTo: string;
  weightFrom: string;
  weightTo: string;
  sku: string;
  ean: string;
  title: string;
  onlyErrors: boolean;
  handlingTimeFrom: string;
  handlingTimeTo: string;
  onlyForcedPrice: boolean;
  holdStatuses: HoldStatus[];
}

interface State {
  filter: Filter;
  filterData: {
    categories: ItemDataType<string>[];
    brands: ItemDataType<string>[];
    statuses: ItemDataType<string>[];
  };
}

const initialState: State = {
  filter: {
    selectedStates: [],
    selectedEshops: [],
    selectedCategories: [],
    selectedBrands: [],
    priceFrom: '',
    priceTo: '',
    stockFrom: '',
    stockTo: '',
    weightFrom: '',
    weightTo: '',
    sku: '',
    ean: '',
    title: '',
    onlyErrors: false,
    handlingTimeFrom: '',
    handlingTimeTo: '',
    onlyForcedPrice: false,
    holdStatuses: [],
  },
  filterData: {
    brands: [],
    categories: [],
    statuses: [],
  },
};

interface Partial<K extends keyof Filter = keyof Filter> {
  key: K,
  value: Filter[K],
}

type FilterSetAction = { type: 'filter/set', payload: Filter };
type FilterPartialAction = { type: 'filter/partial', payload: Partial };
type FilterResetAction = { type: 'filter/reset' };
type FilterDataSetAction = { type: 'filterData/set', payload: ProductsFilterData };
type Action = FilterSetAction | FilterPartialAction | FilterResetAction | FilterDataSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'filter/set':
      return { ...state, filter: action.payload };
    case 'filter/partial':
      return { ...state, filter: { ...state.filter, [action.payload.key]: action.payload.value } };
    case 'filter/reset':
      return { ...state, filter: initialState.filter };
    case 'filterData/set':
      return {
        ...state,
        filterData: {
          brands: action.payload.brands
            .map(brand => ({ value: brand, label: brand })),
          categories: action.payload.categories
            .map(category => ({ value: category, label: category })),
          statuses: action.payload.statuses
            .map(status => ({ value: status, label: status })),
        },
      };
    default:
      return state;
  }
}

function map(globalFilter: string | undefined, filter: Filter): ProductListFilter {
  return {
    globalFilter,
    states: filter.selectedStates,
    eshopIds: filter.selectedEshops,
    categories: filter.selectedCategories,
    brands: filter.selectedBrands,
    ean: filter.ean,
    productCode: filter.sku,
    title: filter.title,
    priceRange: {
      from: filter.priceFrom !== '' ? +filter.priceFrom : null,
      to: filter.priceTo !== '' ? +filter.priceTo : null,
    },
    stockRange: {
      from: filter.stockFrom !== '' ? +filter.stockFrom : null,
      to: filter.stockTo !== '' ? +filter.stockTo : null,
    },
    weightRange: {
      from: filter.weightFrom !== '' ? +filter.weightFrom : null,
      to: filter.weightTo !== '' ? +filter.weightTo : null,
    },
    onlyErrors: filter.onlyErrors,
    handlingTimeRange: {
      from: filter.handlingTimeFrom !== '' ? +filter.handlingTimeFrom : null,
      to: filter.handlingTimeTo !== '' ? +filter.handlingTimeTo : null,
    },
    onlyForcedPrice: filter.onlyForcedPrice,
    holdStatuses: filter.holdStatuses,
  };
}

export default function ProductFilter({ visible }:{ visible: boolean }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { fetchEshops } = useStoreActions(actions => actions.catalogs);
  const { setFilter } = useStoreActions(actions => actions.products);

  const eshops = useStoreState(storeState => storeState.catalogs.eshops);
  const currentFilter = useStoreState(storeState => storeState.products.currentFilter);
  const selectedApiGroup = useStoreState(storeState => storeState.products.apiGroup);

  async function fetchProductsFilterData(apiGroup: ApiGroup) {
    const productsFilterData = await getProductsFilterData(apiGroup);
    dispatch({ type: 'filterData/set', payload: productsFilterData });
  }

  useEffect(() => {
    fetchEshops();
  }, [fetchEshops]);

  useEffect(() => {
    dispatch({ type: 'filter/partial', payload: { key: 'selectedEshops', value: [] } });

    if (!selectedApiGroup) {
      return;
    }

    fetchProductsFilterData(selectedApiGroup).then();
  }, [selectedApiGroup]);

  if (!visible) {
    return null;
  }

  function handleApplyFilters() {
    const { globalFilter } = currentFilter.filter;
    const mappedFilter = map(globalFilter, state.filter);

    setFilter({
      ...currentFilter,
      page: 1,
      filter: mappedFilter,
    });
  }

  function handleClearFilters() {
    dispatch({ type: 'filter/reset' });

    const { globalFilter } = currentFilter.filter;
    const mappedFilter = map(globalFilter, initialState.filter);

    setFilter({
      ...currentFilter,
      page: 1,
      filter: mappedFilter,
    });
  }

  function handleChange(filter: Filter) {
    dispatch({ type: 'filter/set', payload: filter });
  }

  return (
    <Form
      formValue={state.filter}
      onChange={(value) => handleChange(value as Filter)}
      layout="vertical"
      fluid
    >
      <FlexboxGrid align="middle" justify="start" className="flex-box-filters mt-10">
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Marketplace
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            accepter={CheckPicker}
            name="selectedEshops"
            data={eshops.filter(e => e.apiGroup === selectedApiGroup)}
            labelKey="name"
            valueKey="id"
            searchable={false}
            block
            value={state.filter.selectedEshops}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          SKU
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="sku"
            accepter={Input}
            value={state.filter.sku}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Category
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="selectedCategories"
            accepter={CheckPicker}
            style={{ width: 250 }}
            data={state.filterData.categories}
            labelKey="label"
            valueKey="value"
            virtualized
            value={state.filter.selectedCategories}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Price
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Stack spacing={5}>
            <Form.Control
              accepter={InputNumber}
              name="priceFrom"
              value={state.filter.priceFrom}
              min={0}
            />
            <span>-</span>
            <Form.Control
              accepter={InputNumber}
              name="priceTo"
              value={state.filter.priceTo}
              min={0}
            />
          </Stack>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Status
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            accepter={CheckPicker}
            name="selectedStates"
            searchable={false}
            data={state.filterData.statuses}
            labelKey="label"
            valueKey="value"
            block
            value={state.filter.selectedStates}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          EAN
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="ean"
            accepter={Input}
            value={state.filter.ean}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Brand
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="selectedBrands"
            accepter={CheckPicker}
            style={{ width: 250 }}
            data={state.filterData.brands}
            labelKey="label"
            valueKey="value"
            virtualized
            value={state.filter.selectedBrands}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8} />
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="onlyForcedPrice"
            accepter={Checkbox}
            checked={state.filter.onlyForcedPrice}
            value={!state.filter.onlyForcedPrice as any}
          >
            Only products with forced price
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Hold
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="holdStatuses"
            accepter={CheckPicker}
            searchable={false}
            data={getHoldStatusesData()}
            block
            value={state.filter.holdStatuses}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Title
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Form.Control
            name="title"
            accepter={Input}
            value={state.filter.title}
          />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} colspan={8} />
        <FlexboxGrid.Item as={Col} lg={4} colspan={16} />
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Stock
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Stack spacing={5}>
            <Form.Control
              name="stockFrom"
              accepter={InputNumber}
              value={state.filter.stockFrom}
              min={0}
            />
            <span>-</span>
            <Form.Control
              name="stockTo"
              accepter={InputNumber}
              value={state.filter.stockTo}
              min={0}
            />
          </Stack>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={24}>
          <Form.Control
            name="onlyErrors"
            accepter={Checkbox}
            checked={state.filter.onlyErrors}
            value={!state.filter.onlyErrors as any}
          >
            Only products with error
          </Form.Control>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={6} colspan={24}>
          <Stack spacing={10} alignItems="flex-end" justifyContent="flex-end">
            <Button
              appearance="primary"
              style={{ width: 80 }}
              onClick={() => handleApplyFilters()}
            >
              Apply
            </Button>
            <Button
              appearance="primary"
              style={{ width: 80 }}
              onClick={() => handleClearFilters()}
            >
              Clear
            </Button>
          </Stack>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={8} />
        <FlexboxGrid.Item as={Col} lg={4} colspan={16} />
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Weight
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Stack spacing={5}>
            <Form.Control
              name="weightFrom"
              accepter={InputNumber}
              value={state.filter.weightFrom}
              min={0}
            />
            <span>-</span>
            <Form.Control
              name="weightTo"
              accepter={InputNumber}
              value={state.filter.weightTo}
              min={0}
            />
          </Stack>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={2} />
        <FlexboxGrid.Item as={Col} lg={16} />
        <FlexboxGrid.Item as={Col} lg={2} colspan={8}>
          Handling time
        </FlexboxGrid.Item>
        <FlexboxGrid.Item as={Col} lg={4} colspan={16}>
          <Stack spacing={5}>
            <Form.Control
              name="handlingTimeFrom"
              accepter={InputNumber}
              value={state.filter.handlingTimeFrom}
              min={0}
            />
            <span>-</span>
            <Form.Control
              name="handlingTimeTo"
              accepter={InputNumber}
              value={state.filter.handlingTimeTo}
              min={0}
            />
          </Stack>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Form>
  );
}
