import React from 'react';
import { Form, InputPicker, Panel } from 'rsuite';
import { ItemDataType } from 'rsuite/esm/@types/common';
import { OrderListItem } from '../../api/apiTypes';
import { AllegroOrderCarrier } from '../../api/allegroApiTypes';
import { ApiGroup } from '../../api/model/ApiGroup';

export interface TrackingInfo {
  orderListItem: OrderListItem;
  number: string;
  url: string;
  marketplaceCarrierId?: string;
}

function isCarrierVisible(trackingInfo: TrackingInfo): boolean {
  const { apiGroup, eshopCarrierType } = trackingInfo.orderListItem;

  if (apiGroup !== ApiGroup.ALLEGRO) {
    return false;
  }

  return !eshopCarrierType || eshopCarrierType === 'OTHER';
}

export function isTrackingInfoValid(trackingInfo: TrackingInfo): boolean {
  if (!trackingInfo.number) {
    return false;
  }

  const { apiGroup } = trackingInfo.orderListItem;

  if (isCarrierVisible(trackingInfo) && !trackingInfo.marketplaceCarrierId) {
    return false;
  }

  if ([ApiGroup.ALLEGRO, ApiGroup.KAUFLAND].includes(apiGroup)) {
    return true;
  }

  return !!trackingInfo.url;
}

interface Props {
  trackingInfo: TrackingInfo;
  carriers: AllegroOrderCarrier[];
  isLoading: boolean;
  onChange: (trackingInfo: TrackingInfo) => void;
}

function getHeader(orderListItem: OrderListItem): string {
  const { carrier, partnerId, orderNumber } = orderListItem;
  const message = partnerId ? `partner ID ${partnerId},` : '';

  return `For order carried by ${carrier}, ${message} order number ${orderNumber}`;
}

function mapAllegroOrderCarriers(allegroOrderCarriers: AllegroOrderCarrier[]): ItemDataType[] {
  return allegroOrderCarriers
    .map(carrier => ({ label: carrier.name ?? carrier.id, value: carrier.id }));
}

export default function OrdersStatesTrackingInfo({
  trackingInfo,
  carriers,
  isLoading,
  onChange,
}: Props): JSX.Element {
  const {
    marketplaceCarrierId, number, url, orderListItem,
  } = trackingInfo;
  const header = getHeader(orderListItem);
  const mappedCarriers = mapAllegroOrderCarriers(carriers);

  function handleChange(value: string, key: keyof TrackingInfo) {
    onChange({ ...trackingInfo, [key]: value });
  }

  return (
    <Panel header={header}>
      {isCarrierVisible(trackingInfo) && (
      <Form.Control
        accepter={InputPicker}
        data={mappedCarriers}
        labelKey="label"
        valueKey="value"
        name="carrier"
        placeholder="Carrier"
        value={marketplaceCarrierId}
        onChange={(value: string) => handleChange(value, 'marketplaceCarrierId')}
        loading={isLoading}
      />
      )}
      <Form.Control
        name="number"
        placeholder="Tracking Number"
        value={number}
        onChange={(value: string) => handleChange(value, 'number')}
      />
      <Form.Control
        name="url"
        placeholder="Tracking Url"
        value={url}
        onChange={(value: string) => handleChange(value, 'url')}
      />
    </Panel>
  );
}
