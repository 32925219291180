import React, { useEffect, useState } from 'react';
import { Nav } from 'rsuite';
import { Link, useParams } from 'react-router-dom';
import BaseLinkerIntegrationForm from './BaseLinkerIntegrationForm';
import { PARTNER_SETUP } from '../routes/links';
import BaselinkerIntegrationEshopForm from './BaselinkerIntegrationEshopForm';
import { getIntegrationInfo } from '../api/integrationInfo';
import { EshopContextProvider } from './EshopContextProvider';

const apiGroups = [
  { apiGroup: 'ALZA', label: 'Alza' },
  { apiGroup: 'MALL_GROUP', label: 'Mall' },
  { apiGroup: 'ALLEGRO', label: 'Allegro' },
  { apiGroup: 'KAUFLAND', label: 'Kaufland' },
];

export default function BaselinkerIntegrationSetup() {
  const [alreadySetup, setAlreadySetup] = useState<boolean>(false);
  const [blApiToken, setBlApiToken] = useState<string>();
  let { apiGroup } = useParams();
  apiGroup = apiGroup || 'detail';

  useEffect(() => {
    getIntegrationInfo().then(info => {
      if (info.baseLinkerToken) {
        setAlreadySetup(true);
        setBlApiToken(info.baseLinkerToken);
      }
    });
  }, []);

  const onBlSetupSave = (token: string) => {
    setAlreadySetup(true);
    setBlApiToken(token);
  };

  return (
    <div>
      <br />
      <h4>BaseLinker Setup</h4>
      {alreadySetup && (
      <Nav className="nav-large" appearance="subtle" activeKey={apiGroup}>
        <Nav.Item eventKey="detail" as={Link} to={`${PARTNER_SETUP}`}>Setup</Nav.Item>
        {apiGroups.map(partner => (
          <Nav.Item key={partner.apiGroup} eventKey={partner.apiGroup} as={Link} to={`${PARTNER_SETUP}/${partner.apiGroup}`}>
            {partner.label}
          </Nav.Item>
        ))}
      </Nav>
      )}
      <EshopContextProvider>
        {apiGroup === 'detail' && <BaseLinkerIntegrationForm onSave={onBlSetupSave} />}
        {apiGroup !== 'detail' && <BaselinkerIntegrationEshopForm blApiToken={blApiToken} />}
      </EshopContextProvider>
    </div>
  );
}
