import api from '../base';
import { CrossDock } from './crossDockTrackingTypes';
import { mockGetCrossDocks } from './crossDockMocks';

const BASE_PATH = 'management/cross-docks';

// eslint-disable-next-line import/prefer-default-export
export async function getCrossDocks(
  signal?: AbortSignal,
): Promise<CrossDock[]> {
  const response = await api.get<CrossDock[]>(
    BASE_PATH,
    {
      signal,
    },
  );
  return response.data;
}

api.mock
  .onGet('management/cross-docks')
  .reply(200, mockGetCrossDocks);
