import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, CheckPicker, Col, Divider, Grid, Panel, Row, Stack,
} from 'rsuite';

import { FormBox, FormBoxRow } from '../FormBox';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  EXPORT_DELIVERY_DELAY_URL,
  exportProducts,
  IMPORT_DELIVERY_DELAY_URL,
  importProducts,
} from '../../api/products';
import { downloadData } from '../../utils/downloadData';
import FileUploadCsv from '../FileUploadCsv';

export default function DeliveryDelays() {
  const { fetchEshops } = useStoreActions(actions => actions.catalogs);
  const eshops = useStoreState(state => state.catalogs.eshops);
  const [selectedEshops, setSelectedEshops] = useState<string[]>([]);

  const [loading, setLoading] = useState(false);

  const handleExport = async () => {
    const filters = {
      eshopCodes: selectedEshops,
    };

    setLoading(true);
    try {
      const response = await exportProducts(EXPORT_DELIVERY_DELAY_URL, filters);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEshops();
  }, [fetchEshops]);

  async function uploadFile(file:Blob, fileName: string) {
    const data = new FormData();
    data.append('attachment', file, fileName);
    const response = await importProducts(IMPORT_DELIVERY_DELAY_URL, data);
    return response.data;
  }

  function checkAllEshops() {
    if (eshops.length === selectedEshops.length) {
      setSelectedEshops([]);
    } else {
      setSelectedEshops(eshops.map(e => e.code));
    }
  }

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <h6>export</h6>
            <Stack
              spacing={5}
              alignItems="stretch"
              justifyContent="flex-start"
              className="mt-20"
            >
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <FormBox labelWidth={80}>
                  <FormBoxRow label="marketplaces">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={eshops}
                      labelKey="name"
                      valueKey="code"
                      value={selectedEshops}
                      renderExtraFooter={() => (
                        <div className="select-all-container">
                          <Checkbox
                            inline
                            indeterminate={selectedEshops.length > 0
                                    && selectedEshops.length < eshops.length}
                            checked={selectedEshops.length === eshops.length}
                            onChange={() => checkAllEshops()}
                          >
                            Select all
                          </Checkbox>
                        </div>
                      )}
                      onChange={setSelectedEshops}
                    />
                  </FormBoxRow>
                </FormBox>
                <Button
                  className="mt-20"
                  appearance="primary"
                  onClick={() => handleExport()}
                  loading={loading}
                >
                  Export
                </Button>
              </Stack>
            </Stack>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h6>import</h6>
            <FileUploadCsv className="mt-5" getUploadResult={(f, fileName) => uploadFile(f, fileName)} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
