import React, { useState } from 'react';
import Upload from 'rc-upload';
import {
  Button, FlexboxGrid, IconButton, Stack, Tag,
} from 'rsuite';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import pako from 'pako';
import { notificationSuccess } from '../services/notificationActions';
import { downloadData } from '../utils/downloadData';
import { ProductImportResponse } from '../api/apiTypes';
import ProductUploadResult from './ProductUploadResult';
import handleError from '../api/apiErrorHandle';

interface Props {
  getUploadResult(fileData: Blob, fileName: string) : ProductImportResponse | any;
  className?: string;
  disableUploadButton?: boolean;
  autoZip? : boolean
}

function FileUploadCsv({
  getUploadResult, className, disableUploadButton, autoZip,
} : Props) {
  const [selectedFile, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [validationError, setError] = useState('');
  const [errorExtension, setErrorExtension] = useState('csv');
  const [importResult, setImportResult] = useState<ProductImportResponse | null>(null);

  async function handeUpload() {
    if (!selectedFile) {
      return;
    }

    function isTypedResponse(data: ProductImportResponse | any): data is ProductImportResponse {
      const importData = data as ProductImportResponse;
      return importData.productCount !== undefined
          || importData.returnCsv !== undefined
          || importData.errorCount !== undefined;
    }

    function processResponse(data: ProductImportResponse | any) {
      if (data.status === 202) {
        notificationSuccess(data.data, 3500);
        return;
      }
      if (isTypedResponse(data)) {
        const response = data as ProductImportResponse;
        setImportResult(response);
      } else {
        if (data && typeof data === 'string') {
          notificationSuccess('Import successful! (with warnings)');
          setError(data);
          setErrorExtension('csv');
          return;
        }
        if (data && typeof data === 'object' && data.hasErrors) {
          // TODO price and stock update...make csv only?
          setErrorExtension('json');
          setError(JSON.stringify(data.errors));

          return;
        }
        notificationSuccess('Import successful!');
      }
    }

    const isZipped = (file: File): boolean => file.name.endsWith('.xlsx');

    try {
      setError('');
      setUploading(true);

      if (!isZipped(selectedFile) && autoZip) {
        const reader = new FileReader();
        reader.onload = async () => {
          try {
            const deflated = pako.gzip(reader.result!);
            const blob = new Blob([deflated], { type: 'application/zip' });
            const data = await getUploadResult(blob, selectedFile.name);
            processResponse(data);
            setUploading(false);
          } catch (error) {
            setUploading(false);
            handleError(error);
          }
        };
        reader.readAsArrayBuffer(selectedFile);
      } else {
        const data = await getUploadResult(selectedFile, selectedFile.name);
        processResponse(data);
        setUploading(false);
      }
    } catch (error) {
      setUploading(false);
      handleError(error);
    }
  }

  function showReport() {
    downloadData(validationError, 'text/plain', `result.${errorExtension}`);
  }

  function humanFileSize(size:number) {
    if (size === 0) {
      return '0';
    }
    if (size) {
      const i = Math.floor(Math.log(size) / Math.log(1024));
      const s = (size / 1024 ** i).toFixed(2);
      return `${s} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    }

    return '0';
  }

  return (
    <div className={className}>
      <Upload
        type="drag"
        className="uploader"
        accept=".csv"
        beforeUpload={(f) => {
          setFile(f);
          setError('');
          return false;
        }}
      >
        <div>Click or Drag files to this area to upload</div>
      </Upload>

      <FlexboxGrid justify="space-between" className="mt-20">
        <FlexboxGrid.Item colspan={18}>
          {selectedFile
          && (
          <Stack justifyContent="flex-start" spacing={10}>
            <Tag>
              {selectedFile?.name}
              {' '}
              { humanFileSize(selectedFile?.size ?? 0)}
            </Tag>

            <IconButton
              size="xs"
              icon={<WarningRoundIcon />}
              appearance="subtle"
              circle
              disabled={uploading}
              onClick={() => {
                setFile(undefined);
                setError('');
                setImportResult(null);
              }}
            />
          </Stack>
          )}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6}>
          <Stack justifyContent="flex-end" spacing={10}>
            {validationError && (
            <Button
              size="xs"
              appearance="primary"
              color="red"
              onClick={() => showReport()}
            >
              <RemindOutlineIcon />
              {' '}
              Download report
            </Button>
            )}

            <Button
              size="xs"
              appearance="primary"
              disabled={disableUploadButton || selectedFile == null}
              loading={uploading}
              onClick={() => handeUpload()}
            >
              Upload
            </Button>
          </Stack>

        </FlexboxGrid.Item>
      </FlexboxGrid>

      {importResult && <ProductUploadResult result={importResult} />}
    </div>

  );
}

FileUploadCsv.defaultProps = {
  className: null,
  disableUploadButton: false,
  autoZip: true,
};

export default FileUploadCsv;
