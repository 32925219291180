import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CheckPicker,
  Col,
  Divider,
  Grid,
  Panel,
  Row,
  Stack,
  Toggle,
} from 'rsuite';

import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

import { FormBox, FormBoxRow } from '../FormBox';
import { useStoreActions, useStoreState } from '../../store/hooks';
import {
  EXPORT_BRANDS_URL,
  exportProducts,
  IMPORT_BRANDS_URL,
  importProducts,
} from '../../api/products';
import { downloadData } from '../../utils/downloadData';
import FileUploadCsv from '../FileUploadCsv';

export default function BrandDescriptions() {
  const { fetchBrands, fetchEshops } = useStoreActions(actions => actions.catalogs);

  const eshops = useStoreState(state => state.catalogs.eshops);
  const brands = useStoreState(state => state.catalogs.brands);

  const [selectedEshops, setSelectedEshops] = useState<string[]>([]);
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [titleChecked, checkTitle] = useState(true);
  const [brandChecked, checkBrand] = useState(true);
  const [shortDescChecked, checkShortDesc] = useState(false);
  const [longDescChecked, checkLongDesc] = useState(false);
  const [listTitleChecked, checkListTitle] = useState(false);

  const handleExport = async () => {
    const filters = {
      eshopCodes: selectedEshops,
      brands: selectedBrands,
      columns: {
        title: titleChecked,
        listTitle: listTitleChecked,
        brand: brandChecked,
        shortDescription: shortDescChecked,
        longDescription: longDescChecked,
      },
    };

    setLoading(true);
    try {
      const response = await exportProducts(EXPORT_BRANDS_URL, filters);
      downloadData(response.data, 'text/plain', 'export.csv');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
    fetchEshops();
  }, [fetchBrands, fetchEshops]);

  const canExport = () => selectedEshops.length > 0 && (titleChecked || brandChecked
        || shortDescChecked || longDescChecked || listTitleChecked);

  async function uploadFile(file:Blob, fileName: string) {
    const data = new FormData();
    data.append('attachment', file, fileName);
    const response = await importProducts(IMPORT_BRANDS_URL, data);
    return response.data;
  }

  function checkAllEshops() {
    if (eshops.length === selectedEshops.length) {
      setSelectedEshops([]);
    } else {
      setSelectedEshops(eshops.map(e => e.code));
    }
  }

  return (
    <Panel>
      <Grid fluid>
        <Row>
          <Col xs={24}>
            <h6>export</h6>
            <Stack
              spacing={5}
              alignItems="stretch"
              justifyContent="flex-start"
              className="mt-20"
            >
              <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                <FormBox labelWidth={80}>
                  <FormBoxRow label="marketplaces">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={eshops}
                      labelKey="name"
                      valueKey="code"
                      value={selectedEshops}
                      onChange={setSelectedEshops}
                      renderExtraFooter={() => (
                        <div className="select-all-container">
                          <Checkbox
                            inline
                            indeterminate={selectedEshops.length > 0
                                && selectedEshops.length < eshops.length}
                            checked={selectedEshops.length === eshops.length}
                            onChange={() => checkAllEshops()}
                          >
                            Select all
                          </Checkbox>
                        </div>
                      )}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="brands">
                    <CheckPicker
                      style={{ width: 250 }}
                      data={brands}
                      virtualized
                      labelKey="externalId"
                      valueKey="externalId"
                      value={selectedBrands}
                      onChange={setSelectedBrands}
                    />
                  </FormBoxRow>
                </FormBox>
              </Stack>

              <Panel bordered>
                <FormBox labelWidth={120}>
                  <FormBoxRow label="title">
                    <Toggle
                      checked={titleChecked}
                      onChange={checkTitle}
                      checkedChildren={<CheckIcon />}
                      unCheckedChildren={<CloseIcon />}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="brand">
                    <Toggle
                      checked={brandChecked}
                      onChange={checkBrand}
                      checkedChildren={<CheckIcon />}
                      unCheckedChildren={<CloseIcon />}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="short description">
                    <Toggle
                      checked={shortDescChecked}
                      onChange={checkShortDesc}
                      checkedChildren={<CheckIcon />}
                      unCheckedChildren={<CloseIcon />}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="long description">
                    <Toggle
                      checked={longDescChecked}
                      onChange={checkLongDesc}
                      checkedChildren={<CheckIcon />}
                      unCheckedChildren={<CloseIcon />}
                    />
                  </FormBoxRow>
                  <FormBoxRow label="list title">
                    <Toggle
                      checked={listTitleChecked}
                      onChange={checkListTitle}
                      checkedChildren={<CheckIcon />}
                      unCheckedChildren={<CloseIcon />}
                    />
                  </FormBoxRow>
                </FormBox>
              </Panel>
            </Stack>
            <Button
              appearance="primary"
              disabled={!canExport()}
              onClick={() => handleExport()}
              loading={loading}
            >
              Export
            </Button>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Divider />
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <h6>import</h6>
            <FileUploadCsv className="mt-5" getUploadResult={(f, fileName) => uploadFile(f, fileName)} />
          </Col>
        </Row>
      </Grid>
    </Panel>
  );
}
