import { ApiGroup } from './model/ApiGroup';

export type UserPartnerEntity = {
  partnerCode: string;
  partnerName: string;
};

export type User = {
  id: number;
  username: string;
  name: string;
  roles: string[];
  partnerCode: string;
  partnerName: string;
};

export type UserWithPartners = {
  id: number;
  username: string;
  name: string;
  roles: string[];
  partners: UserPartnerEntity[]
};

export type UpdateUserPartnersRequest = {
  userId: number;
  partners: UserPartnerEntity[];
};

export type CreateUserRequest = {
  username: string;
  name: string;
  password: string;
  partners: UserPartnerEntity[];
};

export type ChangePasswordRequest = {
  username: string;
  password: string;
  newPassword: string;
};

export type AdminUpdatePasswordRequest = {
  userId: number;
  newPassword: string;
};

export type BackendExceptionResponse = {
  httpStatus: number;
  code: string;
  codeDescription: string;
  details: string;
};

export type OrderForLabelPrint = {
  id: number;
  originalId: string;
  source: string;
  shippingLabelsPrintMethod: LabelType;
  items: Item[];
  parcelShopBranchId?: string;
  managedByPartner?: boolean;
  apiGroup: ApiGroup;
};

export type LabelType = 'SIMPLE' | 'DETAILED' | undefined;

export type SimpleOrder = {
  id: number;
  partnerId: string | null;
  originalId: string;
  source: string;
  hasLabels: boolean;
};
export type Item = {
  id: string;
  originalId: string;
  count: number;
};

export type DetailedLabelRequest = {
  numberOfPackages: number;
  packages: Package[];
};

export type Package = {
  packageId: number;
  weight?: number;
  dimension1?: number;
  dimension2?: number;
  dimension3?: number;
  volume?: number;
  size?: string;
  items: PackageItem[];
};

export type PackageItem = {
  id: string;
  count: number;
};

export type UpdateStocksPricesResponse = {
  hasErrors: boolean;
  myNumber: number;
  errors: Array<Product>;
  products: Array<Product>;
};

export type Product = {
  productId: string;
  globalOnHand: number;
  salesChannels: Array<string>;
  errorMessage: string
};

export type Category = {
  externalId: string;
};

export type CategoriesResponse = {
  categories: Category[]
};

export type Brand = {
  externalId: string;
  title: string;
};

export type BrandResponse = {
  brands: Brand[]
};

export type ApiGroupEshops = {
  id: number;
  code: string;
  name: string;
};

export type PartnerApiGroup = {
  code: ApiGroup;
  eshops: ApiGroupEshops[];
};

export type Partner = {
  id: number;
  name: string;
  code: string;
  apiGroups: PartnerApiGroup[]
};

export enum Integration {
  MPI = 'MPI',
  BASELINKER = 'BASELINKER',
  SHOPTET = 'SHOPTET',
  WEBEDI = 'WEBEDI',
}

export type PartnerInfo = {
  partnerName?: string;
  integration: Integration;
};

export type IntegrationInfo = {
  baseLinkerToken?: string;
  webEdiGln?: string;
};

export type PartnerEshopResponse = {
  partners: Partner[];
};

export interface PagedResponse<T> {
  total: number,
  page: number,
  pageSize: number,
  data: T[]
}

export interface PagedRequest<T> {
  page: number,
  pageSize: number,
  orderBy: string,
  orderDirection: 'asc' | 'desc',
  filter: T
}

export enum OrderStates {
  RETURNED = 'RETURNED',
  CANCELLED = 'CANCELLED',
  DELIVERED = 'DELIVERED',
  SHIPPED = 'SHIPPED',
  SHIPPING = 'SHIPPING',
  OPEN = 'OPEN',
  RESERVATION = 'RESERVATION',
}

export type UpdateStatus = 'UPDATE_PENDING' | 'UP_TO_DATE';

export type OrderListItem = {
  id: number;
  apiGroup: ApiGroup;
  marketplace: string;
  partnerId?: string;
  orderNumber: string;
  orderValue: number;
  orderValueCurrency: string;
  created: string;
  status: OrderStates;
  statusDate: string;
  carrier: string;
  managedByPartner: boolean;
  suggestedExpedition: string;
  codValue: number;
  codValueCurrency: string;
  packagesCount: number;
  trackingNumber: string;
  shippingLabelUrl: string;
  shippingListUrl: string;
  updateStatus: UpdateStatus;
  updateStatusResponseError: string | null;
  allowedStates: OrderStates[];
  labelsError?: string;
  numberOfCrossDockTrackings?: number;
  crossDockDelivery: boolean;
  eshopCarrierType?: string;
  integration: Integration;
};

export type OrderUpdateStatus = {
  updateStatus: UpdateStatus;
  updateStatusResponseError: string | null;
};

export type RevertedOrderStatus = {
  orderId: number;
  status: OrderStates;
  allowedStatusChanges: OrderStates[]
};

export type OrderForPrintListItem = {
  id: number;
  marketplace: string;
  apiGroup: string;
  orderNumber: string;
  partnerId: string;
  carrier: string;
  managedByPartner: boolean;
  suggestedExpedition: string;
  pagesCount: number;
  crossDockDelivery: boolean;
};

export type ApiDateRange = {
  from: string;
  to: string;
};

export type NumberRange = {
  from: number | null;
  to: number | null;
};

export type CarrierFilter = {
  managedByPartner: boolean;
  apiGroup?: string;
  carrier: string
};

export type OrderListFilter = {
  globalFilter?: string;
  states: OrderStates[];
  eshops?: string[];
  carriers: CarrierFilter[];
  originalOrderId?: string;
  partnerOrderId?: string;
  orderValue?: NumberRange;
  expedition?: ApiDateRange;
  created?: ApiDateRange;
  delivered?: ApiDateRange;
  shipping?: ApiDateRange;
  returned?: ApiDateRange;
  shipped?: ApiDateRange;
  cancelled?: ApiDateRange;
  crossDockDelivery?: boolean;
};

export type DeliveryTypes = 'HOME_DELIVERY' | 'ALZA_PICKUP' | 'CUSTOMER_PICKUP_SHOP' | 'CUSTOMER_PICKUP_BOX';

export type MergeFormat = 'A4' | 'CONTINUOUS';

export type PrintExistingOrdersFilter = {
  globalFilter?: string;
  deliveryTypes: DeliveryTypes[]
};

export type LabelsMergeRequest = {
  format: MergeFormat;
  orderIds: number[];
};

export type OrderStateUpdateEntity = {
  id: number;
  newState: OrderStates;
  trackingNumber: string;
  trackingUrl: string;
  cancelReason: string;
  marketplaceCarrierId?: string;
};

export type OrderPreviewProduct = {
  orderProductCode: string,
  title: string,
  price: number,
  quantity: number,
  currency: string,
  vat: number
};

export type OrderPreviewCustomer = {
  name: string,
  company?: string,
  phone?: string,
  email?: string,
  address1?: string,
  address2?: string,
  city?: string,
  zip?: string,
};

export type OrderPreview = {
  products: OrderPreviewProduct [],
  customer: OrderPreviewCustomer,

  parcelTracking: {
    customer: string [];
    crossDock: string[]
  }
};

export type ApiCarrier = {
  group: string;
  identifier: string;
};

export type PackageSizes = {
  size: string;
  weight: number;
  length: number;
  width: number;
  height: number;
};

export enum ProductStates {
  TO_BE_PUBLISHED = 'TO_BE_PUBLISHED',
  DRAFT = 'DRAFT',
  PUBLISHED_TEST = 'PUBLISHED_TEST',
  TO_BE_ACTIVATED = 'TO_BE_ACTIVATED',
  PUBLISHED_LIVE = 'PUBLISHED_LIVE',
  IMPORT_FAILED = 'IMPORT_FAILED',
  UPDATE_PENDING = 'UPDATE_PENDING',
  UPDATE_FAILED = 'UPDATE_FAILED',
  TO_BE_DELETED = 'TO_BE_DELETED',
  DELETION_FAILED = 'DELETION_FAILED',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  MPI_ONLY = 'MPI_ONLY',
  ACTIVATION_FAILED = 'ACTIVATION_FAILED',
}

export enum HoldStatus {
  ON_HOLD = 'ON_HOLD',
  NOT_ON_HOLD = 'NOT_ON_HOLD',
}

export enum ProductTypes {
  PRODUCT = 'PRODUCT',
  VARIANT = 'VARIANT',
}

export type ProductListItem = {
  id: number;
  type: ProductTypes;
  partnerId: number;
  partnerCode: string;
  partnerName: string;
  eshopId: number;
  eshopCode: string;
  eshopName: string;
  partnerProductCode: string;
  ean: string;
  eshopProductCode: string;
  title: string;
  category: string;
  brand: string;
  price: number;
  forcedPrice: number;
  useForcedPrice: boolean;
  currency: string;
  status: ProductStates;
  deliveryDelay: number;
  freeDelivery: boolean;
  parentId: number;
  inStock: number;
  weight: number;
  weightUom: string;
  errorMessage: string;
  errorUpdateMessage: string;
  asyncOperationId: string | null;
  branchCode?: string;
  shippingGroup?: string;
  handlingTime?: number;
  minimumPrice?: number;
  temporaryHold: boolean;
};

export type ProductListFilter = {
  globalFilter?: string;
  eshopIds: number[]
  states: ProductStates[]
  categories: string[];
  brands: string[];
  productCode?: string;
  ean?: string;
  title?: string;
  priceRange?: NumberRange;
  stockRange?: NumberRange;
  weightRange?: NumberRange;
  onlyErrors?: boolean;
  handlingTimeRange?: NumberRange;
  onlyForcedPrice?: boolean;
  holdStatuses: HoldStatus[];
};

export interface ProductImportResponse {
  productCount?: number;
  errorCount?: number;
  draftCount?: number;
  updatedProducts?: number;
  createdProducts?: number;
  updatedVariants?: number;
  createdVariants?: number;
  errorCsv?: string;
  returnCsv?: string;
}

export interface AllegroUser {
  id: string;
  login: string;
  firstName: string;
  lastName: string;
  email: string;
  company: {
    name: string,
    tax: string
  };
  baseMarketplace: {
    id: string;
  }
}

export interface AllegroLinkingInfo {
  linked: boolean;
  linkUrl: string | null;
  allegroUser: AllegroUser | null;
  eshopCode: string;
  errorMessage?: string;
}

export interface SerialNumber {
  id: number;
  orderLineId: number;
  serialNumber?: string;
}

export interface OrderItem {
  id: number;
  partnerProductCode?: string;
  ean?: string;
  orderProductCode?: string;
  title?: string;
  serialNumbers: SerialNumber[];
}

export interface UpdateSerialNumbers {
  serialNumbers: SerialNumber[];
}

export interface OrderWithItems extends OrderListItem {
  items: OrderItem[];
}

export interface KauflandExternalShippingGroup {
  currency: string;
  shippingGroupId: number;
  storefront: string;
  name: string;
  type: string;
  isDefault: boolean;
  regions: KauflandExternalShippingGroupRegion[];
}

export interface KauflandExternalShippingGroupRegion {
  countries: string[];
  shippingOptions: KauflandExternalShippingGroupOption[];
}

export interface KauflandExternalShippingGroupOption {
  name: string;
  firstCost: number;
  nextCost: number;
  maxCost: number;
  freeCost: number;
  cutOffTime: string;
  minimumTransportTime: number;
  maximumTransportTime: number;
}

export interface KauflandExternalWarehouse {
  warehouseId: number;
  name: string;
  address: KauflandExternalWarehouseAddress;
  isDefault: boolean;
  type: string;
}

export interface KauflandExternalWarehouseAddress {
  street: string;
  city: string;
  houseNumber: string;
  postcode: string;
  country: string;
  phone: string;
}

export interface KauflandTransportMapping {
  partnerCode: string;
  shippingGroupId: string;
  eshopCarrierId: number;
}

export enum MpiDeliveryType {
  PARCEL_SHOP = 'PARCEL_SHOP',
  PARCEL_BOX = 'PARCEL_BOX',
  HOME_DELIVERY = 'HOME_DELIVERY',
}

export const MpiDeliveryTypeLabels = {
  [MpiDeliveryType.PARCEL_SHOP]: 'Parcel shop',
  [MpiDeliveryType.PARCEL_BOX]: 'Parcel box',
  [MpiDeliveryType.HOME_DELIVERY]: 'Home delivery',
};

export interface EshopCarrier {
  id: number;
  eshopCode: string;
  name?: string;
  portalName?: string;
  eshopCarrierCode: string;
  eshopCarrierType?: string;
  carrierId: number;
  defaultMpiDeliveryType?: MpiDeliveryType;
  maxWeight?: number;
  managedByPartner?: boolean;
  maxParcelCount?: number;
  ignoreIban: boolean;
  identifier: string;
  secondaryEshopCarrierCode?: string;
}

export interface KauflandCarrier {
  eshopCarrierId: number;
  carrierCode: string;
}

export interface KauflandExternalCarrier {
  name: string;
  isHauler: boolean;
}

export interface Carrier {
  id: number;
  identifier: string;
}

export interface PartnerEshopBankDetails {
  bankAccount?: string;
  bankAccountName?: string;
}

export interface PartnerEshopDefaults {
  defaultShippingRateId?: string;
  defaultReturnPolicyId?: string;
  defaultWarrantyId?: string;
}

export interface PartnerEshopCrossDock {
  enabled: boolean;
  code: string;
  generateLabels: boolean;
}

export interface OrderImportResult {
  totalCount: number;
  updatedCount: number;
  failedCount: number;
  warningCount: number;
  errorReport?: string;
  reimportReport?: string;
}

export interface OffersBulkActionResponse {
  total: number;
  failed: number;
  report?: string;
}

export interface ProductsFilterData {
  brands: string[];
  categories: string[];
  statuses: ProductStates[];
}
