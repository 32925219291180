import React from 'react';
import { Stack } from 'rsuite';
import { FaBoxOpen, FaPrint } from 'react-icons/fa';
import { MdOutlinePinInvoke } from 'react-icons/md';
import { Integration, OrderListItem, OrderStates } from '../../api/apiTypes';
import { insertOrderToExternalSystem } from '../../api/orders';
import showSuccessMessage from '../../utils/message';

interface Props {
  order: OrderListItem;
  onCreateShippingLabel?: (order: OrderListItem) => void;
  onAddCrossDockTracking?: (order: OrderListItem) => void;
}

function getIntegrationMessage(integration: Integration): string {
  return `Resend the order data to the partner's ${integration.toLowerCase()}.`;
}

function isIntegrationActionVisible(order: OrderListItem): boolean {
  if (order.integration === Integration.MPI) {
    return false;
  }

  return !order.partnerId;
}

export default function OrdersTableAction({
  order,
  onCreateShippingLabel,
  onAddCrossDockTracking,
}: Props) {
  if (order.managedByPartner) {
    return null;
  }

  const isCreateShippingLabelVisible = order.status === OrderStates.SHIPPING && order.updateStatus === 'UP_TO_DATE';
  const isCrossDockTrackingFilled = (order.numberOfCrossDockTrackings ?? 0) > 0;
  const crossDockTrackingColor = isCrossDockTrackingFilled ? '#4caf50' : 'var(--rs-gray-600)';

  function handleCreateShippingLabel() {
    onCreateShippingLabel?.(order);
  }

  function handleAddCrossDockTracking() {
    onAddCrossDockTracking?.(order);
  }

  async function handleInsertOrderToExternalSystem() {
    const { id, integration } = order;
    await insertOrderToExternalSystem(id);

    showSuccessMessage(`Inserting order to ${integration.toLowerCase()} successfully invoked.`);
  }

  return (
    <Stack spacing={10}>
      {isCreateShippingLabelVisible && (
      <FaPrint
        title="Create shipping label"
        cursor="pointer"
        onClick={() => handleCreateShippingLabel()}
      />
      )}
      <FaBoxOpen
        title="Add tracking info"
        cursor="pointer"
        color={crossDockTrackingColor}
        onClick={() => handleAddCrossDockTracking()}
      />
      {
        isIntegrationActionVisible(order) && (
        <MdOutlinePinInvoke
          title={getIntegrationMessage(order.integration)}
          cursor="pointer"
          onClick={() => handleInsertOrderToExternalSystem()}
          size={20}
        />
        )
      }
    </Stack>
  );
}

OrdersTableAction.defaultProps = {
  onCreateShippingLabel: undefined,
  onAddCrossDockTracking: undefined,
};
