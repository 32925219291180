import { PartnerEshopBankDetails, PartnerEshopCrossDock, PartnerEshopDefaults } from '../apiTypes';

export const mockGetPartnerEshopBankDetails: PartnerEshopBankDetails = {
  bankAccount: '111222333/444',
  bankAccountName: 'Joe Doe',
};

export const mockGetPartnerEshopDefaults: PartnerEshopDefaults = {
  defaultShippingRateId: '7470ffe8-8691-4c35-9f2b-a8e527b523bc',
  defaultReturnPolicyId: 'd1cbcd9e-6042-4b1b-8e4f-3ba00d94ed32',
  defaultWarrantyId: '9a908d1f-dd30-4c30-87ad-b82ce5d696b8',
};

export const mockGetPartnerCrossDock: PartnerEshopCrossDock = {
  enabled: true,
  code: 'OLZA-1',
  generateLabels: false,
};
