import React from 'react';
import { FaFileCsv } from 'react-icons/fa';
import {
  Button, Form, Loader, Modal, Schema, Stack,
} from 'rsuite';
import CardActionImportGuide from './CardActionImportGuide';
import { downloadData } from '../utils/downloadData';
import {
  createReportExistingProductMissingParameters,
} from '../api/products';

export default function DownloadExistingOffersMissingAttributesReport() {
  const [open, setOpen] = React.useState<boolean>(false);
  const [generating, setGenerating] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setGenerating(false);
    setCategories('');
  };

  const downloadFile = async () => {
    setGenerating(true);
    const response = await createReportExistingProductMissingParameters(categories);
    downloadData(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'report-new-product-parameters.xlsx');
    handleClose();
  };

  const model = Schema.Model({
    categories: Schema.Types.StringType().pattern(/^[0-9]*[;0-9]+$/, 'Categories must be a number separated by semicolon'),
  });

  return (
    <div>
      <CardActionImportGuide onClick={() => setOpen(true)}>
        <FaFileCsv />
        <span>Export existing offers with missing parameters</span>
      </CardActionImportGuide>
      <Modal
        open={open}
        backdrop="static"
      >
        <Modal.Header closeButton={false}>
          <Modal.Title>Generate report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {generating ? (
            <div style={{ textAlign: 'center' }}>
              <Loader size="md" />
            </div>
          ) : (
            <Form model={model}>
              <Form.Group>
                <Form.Control
                  name="categories"
                  placeholder="Enter categories separated by semicolon"
                  value={categories}
                  onChange={(value: string) => setCategories(value)}
                />
              </Form.Group>
              <Stack spacing={5}>
                <Button type="submit" disabled={generating} onClick={() => downloadFile()} appearance="primary">
                  Generate Report
                </Button>
                <Button disabled={generating} onClick={() => handleClose()} appearance="subtle">
                  Close
                </Button>
              </Stack>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
