import { CrossDock } from './crossDockTrackingTypes';

// eslint-disable-next-line import/prefer-default-export
export const mockGetCrossDocks: CrossDock[] = [{
  address: {
    addressLine1: 'ul. Św. Antoniego',
    city: 'Praha',
    country: 'CZ',
    email: 'info@mall.cz',
    name: 'Josef Novák',
    phone: '+420701000001',
    zip: '17001',
  },
  code: 'OLZA-1',
},
{
  address: {
    addressLine1: 'U garáží 1',
    city: 'Wroclaw',
    country: 'PL',
    email: 'info@mall.pl',
    name: 'Józef Nowak',
    phone: '+48123456789',
    zip: '50-155',
  },
  code: 'OLZA-2',
}];
