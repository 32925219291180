import {
  ApiCarrier, BrandResponse, CategoriesResponse, PackageSizes, PartnerEshopResponse,
} from '../apiTypes';
import { ApiGroup } from '../model/ApiGroup';

export const mockGetCategories: CategoriesResponse = {
  categories: [
    {
      externalId: 'N068',
    },
    {
      externalId: 'N684',
    },
    {
      externalId: 'N001',
    },
    {
      externalId: 'N123',
    },
  ],
};

export const mockGetBrands: BrandResponse = {
  brands: [
    {
      externalId: 'BOSCH',
      title: 'Bosch',

    },
    {
      externalId: 'TS',
      title: 'Tesla',
    },
  ],
};

export const mockGetPartnerEshops: PartnerEshopResponse = {
  partners: [
    {
      id: 1,
      name: 'Partner',
      code: 'p1',
      apiGroups: [
        {
          code: ApiGroup.MALL_GROUP,
          eshops: [
            {
              id: 1,
              name: 'MALL.CZ',
              code: 'Mall cz',
            },
            {
              id: 2,
              name: 'MALL.SK',
              code: 'Mall sk',
            },
            {
              id: 3,
              name: 'ALZA',
              code: 'Alza',
            },
            {
              id: 4,
              name: 'Mimovrste',
              code: 'MIMOVRSTE',
            },
          ],
        },
      ],
    },
  ],
};

export const mockGetCarriers : ApiCarrier[] = [
  {
    group: 'MALL',
    identifier: 'DPD',
  },
  {
    group: 'MALL',
    identifier: 'PPL',
  },
  {
    group: 'Alza',
    identifier: 'DPD',
  },
  {
    group: 'PRIVATE',
    identifier: 'DPD',
  },
];

export const mockGetCancelReasons: String[] = ['out_of_stock', 'damaged_goods', 'wrong_price', 'external_supplier_failure', 'marketplace_request', 'other'];

export const mockGetPackageSizes : PackageSizes[] = [
  {
    size: 'XS',
    weight: 2000.0,
    length: 20.0,
    width: 30.0,
    height: 20.0,
  },
  {
    size: 'S',
    weight: 5000.0,
    length: 40.0,
    width: 30.0,
    height: 30.0,
  },
  {
    size: 'SM',
    weight: 10000.0,
    length: 60.0,
    width: 40.0,
    height: 30.0,
  },
  {
    size: 'M',
    weight: 15000.0,
    length: 70.0,
    width: 40.0,
    height: 40.0,
  },
  {
    size: 'ML',
    weight: 20000.0,
    length: 80.0,
    width: 40.0,
    height: 50.0,
  },
  {
    size: 'L',
    weight: 31000.0,
    length: 90.0,
    width: 40.0,
    height: 50.0,
  },
  {
    size: 'XL',
    weight: 50000.0,
    length: 120.0,
    width: 80.0,
    height: 90.0,
  },
  {
    size: 'XXL',
    weight: 200000.0,
    length: 120.0,
    width: 160.0,
    height: 120.0,
  },
];
