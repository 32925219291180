import React from 'react';
import { Divider, Panel, Stack } from 'rsuite';
import {
  fullImportProducts,
} from '../api/products';

import DownloadTemplate from '../components/DownloadTemplate';
import Guidelines from '../components/Guidelines';
import FileUploadCsv from '../components/FileUploadCsv';

export default function ImportProducts() {
  async function uploadFile(file:Blob, fileName: string) {
    const data = new FormData();
    data.append('attachment', file, fileName);
    const response = await fullImportProducts(data);
    return response.data;
  }

  return (
    <Panel>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
        <Guidelines />
        <DownloadTemplate filename="template.csv" />
      </Stack>
      <Divider />
      <h6>import</h6>
      <FileUploadCsv className="mt-5" getUploadResult={(f, fileName) => uploadFile(f, fileName)} />
    </Panel>
  );
}
