import React, { useEffect, useReducer } from 'react';
import { Form, PanelGroup } from 'rsuite';
import _ from 'lodash';
import { AllegroOrderCarrier } from '../../api/allegroApiTypes';
import { getAllegroOrderCarriers } from '../../api/allegro';
import OrdersStatesTrackingInfo, { TrackingInfo } from './OrdersStatesTrackingInfo';
import { ApiGroup } from '../../api/model/ApiGroup';

interface Carriers {
  [marketplaceCode: string]: AllegroOrderCarrier[];
}

interface Props {
  trackingInfos: TrackingInfo[];
  onChange: (trackingInfos: TrackingInfo[]) => void;
}

interface State {
  status: 'ready' | 'loading';
  carriers: Carriers;
}

const initialState: State = {
  status: 'ready',
  carriers: {},
};

type StatusSetAction = { type: 'status/set', payload: 'ready' | 'loading' };
type CarriersSetAction = { type: 'carriers/set', payload: Carriers };
type Action = StatusSetAction | CarriersSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'carriers/set':
      return { ...state, carriers: action.payload, status: 'ready' };
    default:
      return state;
  }
}

function getCarriers(trackingInfo: TrackingInfo, carriers: Carriers): AllegroOrderCarrier[] {
  const marketplaceCode = trackingInfo.orderListItem.marketplace;
  return carriers[marketplaceCode] ?? [];
}

export default function OrdersStatesTrackingInfos({ trackingInfos, onChange } : Props):JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);

  async function fetchData() {
    const marketplaceCodes = trackingInfos
      .filter(trackingInfo => trackingInfo.orderListItem.apiGroup === ApiGroup.ALLEGRO)
      .map(trackingInfo => trackingInfo.orderListItem.marketplace);

    if (marketplaceCodes.length === 0) {
      return;
    }

    dispatch({ type: 'status/set', payload: 'loading' });

    const requests = marketplaceCodes
      .map(marketplaceCode => getAllegroOrderCarriers(marketplaceCode)
        .then(response => ({ response, marketplaceCode })));

    const result = await Promise
      .all(requests)
      .then(responses => responses
        .map(response => ({ [response.marketplaceCode]: response.response })));

    dispatch({ type: 'carriers/set', payload: _.assign({}, ...result) });
  }

  useEffect(() => {
    fetchData().then();
  }, [trackingInfos]);

  function handleChange(updatedTrackingInfo: TrackingInfo) {
    const updatedTrackingInfos = trackingInfos
      .map(trackingInfo => {
        if (trackingInfo.orderListItem.id !== updatedTrackingInfo.orderListItem.id) {
          return trackingInfo;
        }

        return updatedTrackingInfo;
      });

    onChange([...updatedTrackingInfos]);
  }

  return (
    <Form layout="horizontal">
      <PanelGroup>
        {trackingInfos.map(trackingInfo => (
          <OrdersStatesTrackingInfo
            key={trackingInfo.orderListItem.id}
            trackingInfo={trackingInfo}
            carriers={getCarriers(trackingInfo, state.carriers)}
            isLoading={state.status === 'loading'}
            onChange={(value) => handleChange(value)}
          />
        ))}
      </PanelGroup>
    </Form>
  );
}
