import React, { useEffect, useReducer, useRef } from 'react';
import {
  Button, ButtonToolbar, Form, Schema,
} from 'rsuite';
import { getIntegrationInfo, saveIntegrationInfo } from '../api/integrationInfo';
import { IntegrationInfo } from '../api/apiTypes';
import showSuccessMessage from '../utils/message';
import { utils } from '../utils/utils';

const model = Schema.Model({
  webEdiGln: Schema.Types.StringType().isRequired('GLN is required.'),
});

interface Edit {
  webEdiGln: string;
}

interface State {
  status: 'ready' | 'loading' | 'saving',
  integrationInfo?: IntegrationInfo,
  edit: Edit,
}

const initialState: State = {
  status: 'ready',
  edit: {
    webEdiGln: '',
  },
};

type StatusSetAction = { type: 'status/set', payload: 'ready' | 'loading' | 'saving' };
type IntegrationInfoSetAction = { type: 'integrationInfo/set', payload: IntegrationInfo };
type EditSetAction = { type: 'edit/set', payload: Edit };
type Action = StatusSetAction | IntegrationInfoSetAction | EditSetAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'status/set':
      return { ...state, status: action.payload };
    case 'integrationInfo/set':
      return {
        ...state,
        integrationInfo: action.payload,
        edit: {
          webEdiGln: utils.defaultToEmpty(action.payload.webEdiGln),
        },
      };
    case 'edit/set':
      return { ...state, edit: action.payload };
    default:
      return state;
  }
}

function mapEditToIntegrationInfo(edit: Edit, integrationInfo: IntegrationInfo): IntegrationInfo {
  return { ...integrationInfo, webEdiGln: edit.webEdiGln || undefined };
}

export default function WebEdiIntegrationSetup() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef<any>();

  async function fetchData() {
    dispatch({ type: 'status/set', payload: 'loading' });

    try {
      const integrationInfo = await getIntegrationInfo();
      dispatch({ type: 'integrationInfo/set', payload: integrationInfo });
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  async function saveData() {
    if (!formRef.current?.check()) {
      return;
    }

    if (!state.integrationInfo) {
      return;
    }

    dispatch({ type: 'status/set', payload: 'saving' });
    try {
      const integrationInfo = mapEditToIntegrationInfo(state.edit, state.integrationInfo);
      await saveIntegrationInfo(integrationInfo);
      showSuccessMessage('GLN successfully saved.');
    } finally {
      dispatch({ type: 'status/set', payload: 'ready' });
    }
  }

  useEffect(() => {
    fetchData().then();
  }, []);

  function handleChange(edit: Edit) {
    dispatch({ type: 'edit/set', payload: edit });
  }

  function handleSubmit() {
    saveData().then();
  }

  return (
    <div>
      <br />
      <h4>WebEdi Setup</h4>
      <br />
      <Form
        layout="horizontal"
        formValue={state.edit}
        onChange={(edit) => handleChange(edit as Edit)}
        model={model}
        ref={formRef}
      >
        <Form.Group>
          <Form.ControlLabel>GLN</Form.ControlLabel>
          <Form.Control name="webEdiGln" value={state.edit.webEdiGln} />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              onClick={() => handleSubmit()}
              loading={state.status === 'saving'}
            >
              Submit
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
}
